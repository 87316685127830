import { MetaData } from "@/interfaces/MetaData";
import Head from "next/head";
const HeadMeta = ({
  title,
  description = process.env.SITE_DESCRIPTION,
  path = process.env.appUrl,
  thumbnail = `${process.env.storageUrl}/public/screenshot.jpg`,
  preloadImage,
}: MetaData) => {
  const siteName = process.env.SITE_NAME;
  const pageTitle = `${title ? `${title} | ` : ""}${siteName}`;
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#90c652" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/icon-192x192.png"
      />
      <meta name="application-name" content={siteName} />
      <meta name="apple-mobile-web-app-title" content={siteName} />
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:type" content="blog" />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={path} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content="ja_JP" />
      <meta name="twitter:site" content="@chiffon0903" />
      <meta name="twitter:card" content="summary" />
      {preloadImage && <link rel="preload" as="image" href={preloadImage} />}
    </Head>
  );
};

export default HeadMeta;
