import { useOnScreen } from "@/hooks/useOnScreen";
import { ReactNode, useEffect, useRef } from "react";

type Props = {
  children: ReactNode;
  rootMargin?: number;
  minHeight?: number;
  className?: string;
  trigger?: () => void;
};

const VisibilityWrapper = ({
  children,
  rootMargin,
  minHeight,
  className,
  trigger,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null!);
  const isVisible = useOnScreen(ref, rootMargin);
  useEffect(() => {
    if (trigger && isVisible) trigger();
  }, [isVisible]);
  return (
    <div
      ref={ref}
      className={className}
      style={{ minHeight: `${(!isVisible && minHeight) || 0}px` }}
    >
      {isVisible && children}
    </div>
  );
};

export default VisibilityWrapper;
