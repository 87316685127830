import { useOnScreen } from "@/hooks/useOnScreen";
import { useEffect, useRef, useState } from "react";

type Props = {
  isCompleted: boolean;
  onVisible: (val: boolean) => Promise<void>;
};

const Loading = ({ isCompleted, onVisible }: Props) => {
  const ref = useRef<HTMLDivElement>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const isVisible = useOnScreen(ref, 100, false);
  useEffect(() => {
    if (isCompleted || isLoading || !isVisible) return;
    setIsLoading(true);
    onVisible(isVisible).then(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    });
  }, [isVisible, isLoading]);
  return (
    <>
      {!isCompleted && <div ref={ref} className="loader"></div>}
      <style jsx>{`
        .loader {
          margin: 20px auto;
          width: 2em;
          height: 2em;
          border: 4px solid #bbb;
          border-right-color: transparent;
          border-top-color: transparent;
          // animation: spinAround 1s infinite linear;
        }
      `}</style>
    </>
  );
};

export default Loading;
