import { Post } from "@/interfaces/Post";
import VisibilityWrapper from "../atoms/VisibilityWrapper";
import Loading from "./Loading";
import PostOverview from "./PostOverview";
import PostOverviewLoading from "./PostOverviewLoading";

type Props = {
  posts: Post[];
  isLoading?: boolean;
  isFetchCompleted: boolean;
  fetchMorePosts: () => Promise<void>;
};

const PostList = ({
  posts,
  isFetchCompleted,
  fetchMorePosts,
  isLoading = false,
}: Props) => {
  if (isLoading) {
    return (
      <>
        {[...Array(10)].map((_, i) => (
          <PostOverviewLoading key={i} />
        ))}
      </>
    );
  }
  const onVisible = async (isVisible: boolean) => {
    if (!isVisible) return;
    await fetchMorePosts();
  };
  return (
    <>
      {posts.map((post, i) =>
        i < 2 ? (
          <PostOverview post={post} key={post.id} />
        ) : (
          <VisibilityWrapper key={post.id} rootMargin={100} minHeight={400}>
            <PostOverview post={post} />
          </VisibilityWrapper>
        )
      )}
      {posts.length >= 5 && (
        <Loading isCompleted={isFetchCompleted} onVisible={onVisible} />
      )}
    </>
  );
};

export default PostList;
