import Layout from "@/components/Layout";
import { deserializePost, Post, serializePost } from "@/interfaces/Post";
import { InferGetStaticPropsType } from "next";
import { useApiClient } from "@/hooks/useApiClient";
import { useEffect, useState } from "react";
import { fetchSidebarJotai } from "@/hooks/useSidebarJotai";
import HeadMeta from "@/components/layouts/HeadMeta";
import PostList from "@/components/posts/PostList";
import { useRouter } from "next/router";
import { CFApiClient } from "@/utils/api/CFApiClient";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

const getPosts = async ({
  api,
  keyword,
  lastDate,
  page,
}: {
  api: CFApiClient;
  keyword?: string;
  lastDate?: Date;
  page?: number;
}) => {
  if (keyword && page) {
    return await api.searchPosts(10, keyword, page);
  } else if (lastDate) {
    return (await api.getPosts(10, lastDate)).map((post) => new Post(post));
  } else {
    return (await api.recentPosts()).map((post) => new Post(post));
  }
};

const IndexPage = (props: Props) => {
  const posts = props.posts.map((post) => deserializePost(post));
  const [isFetchCompleted, setIsFetchCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [fetchedPosts, setFetchedPosts] = useState(posts);
  const { query } = useRouter();
  const api = useApiClient();
  const fetchMorePosts = async () => {
    const lastDate = fetchedPosts[fetchedPosts.length - 1].date;
    let posts = [];
    if (searchKeyword) {
      posts = await getPosts({
        api,
        page: Math.ceil(fetchedPosts.length / 10) + 1,
        keyword: searchKeyword,
      });
    } else {
      posts = await getPosts({ api, lastDate });
    }
    setFetchedPosts([...fetchedPosts, ...posts]);
    if (posts.length < 10) setIsFetchCompleted(true);
  };
  useEffect(() => {
    if (typeof query.s === "string") {
      setSearchKeyword(query.s);
    } else if (searchKeyword !== undefined) {
      setSearchKeyword("");
    }
  }, [query.s]);
  useEffect(() => {
    // 初回描画時はreturn
    if (searchKeyword === undefined) return;
    (async () => {
      setIsLoading(true);
      const newPosts = searchKeyword
        ? await getPosts({
            api,
            page: 1,
            keyword: searchKeyword,
          })
        : posts;
      setFetchedPosts(newPosts);
      setIsFetchCompleted(false);
      setIsLoading(false);
    })();
  }, [searchKeyword]);
  const preloadImage = posts.find((post, i) => i < 2 && post.thumbnail.large)
    ?.thumbnail.large;
  return (
    <Layout sidebarJotai={props.sidebarJotai}>
      <HeadMeta
        title={undefined}
        description={undefined}
        path={undefined}
        thumbnail={undefined}
        preloadImage={preloadImage}
      />
      <PostList
        posts={fetchedPosts}
        isLoading={isLoading}
        isFetchCompleted={isFetchCompleted}
        fetchMorePosts={fetchMorePosts}
      />
    </Layout>
  );
};

export default IndexPage;

export const getStaticProps = async () => {
  const api = useApiClient();
  const posts = await api.getPosts(10);
  const data = await fetchSidebarJotai(api);
  return {
    props: {
      posts: posts.map((p) => serializePost(new Post(p))),
      sidebarJotai: data,
    },
  };
};
