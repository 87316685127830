import { useClient } from "@/hooks/useClient";
import { Post } from "@/interfaces/Post";
import { dateFromNow } from "@/utils/dateutil";
import Link from "next/link";
import { useEffect, useState } from "react";
import firebase from "@/utils/api/firebase";

type Props = {
  post: Post;
};
const PostOverview = ({ post }: Props) => {
  const [likeCount, setLikeCount] = useState(0);
  const { isClient } = useClient();
  useEffect(() => {
    let unmounted = false;
    const likeCountRef = firebase.database().ref(`/like/count/${post.id}`);
    likeCountRef.once("value", (snapshot) => {
      if (unmounted) return;
      setLikeCount(snapshot.val() ? Number(snapshot.val()) : 0);
    });
    return () => {
      unmounted = true;
    };
  }, [post.id]);
  return (
    <article id={`post-${post.id}`} className="box is-paddingless">
      {post.thumbnail?.large && (
        <Link href={`/archives/${post.id}`}>
          <a className="card-image">
            <figure className="image is-16by9">
              <img
                className="lazyload"
                data-src={post.thumbnail.large}
                data-src-set={`${post.thumbnail.medium} 480w,
             ${post.thumbnail.large} 786w`}
                alt="post.title"
              />
            </figure>
          </a>
        </Link>
      )}
      <Link href={`/archives/${post.id}`}>
        <a className="card-content is-block">
          <div className="tags has-addons">
            {post.categoryName && (
              <span
                className={`tag is-rounded is-primary is-marginless category-${post.categorySlug}`}
              >
                {post.categoryName.replace(/\*/g, "")}
              </span>
            )}
            <span className="tag is-rounded is-marginless">
              {isClient && dateFromNow(post.date)}
            </span>
          </div>
          <p className="title has-text-link article-title is-size-4 is-size-5-mobile">
            {post.title}
          </p>
          <div className="my-excerpt">
            <p className="content">
              <span>{post.excerpt}</span> ...
            </p>
          </div>
          <div className="buttons is-marginless">
            <div className="button is-white is-paddingless">
              <i className="material-icons mdi-comment is-size-6"></i>
              <span>&nbsp;{post.commentCount}</span>
            </div>
            <div className="button is-white is-paddingless has-text-danger">
              <i className="material-icons mdi-favorite is-size-6"></i>
              <span>&nbsp;{likeCount + post.likeCount}</span>
            </div>
          </div>
        </a>
      </Link>
      <div className="card-footer">
        <div className="card-footer-item columns">
          {post.tags && post.tags.length > 0 && (
            <div className="tags column">
              {post.tags.map((tag) => {
                return (
                  <Link
                    key={"post-" + post.id + "_tag-" + tag.slug}
                    href={`/tag/${tag.slug}`}
                  >
                    <a className="tag my-post-tag">
                      <i className="material-icons mdi-tag is-size-6"></i>
                      {tag.name}
                    </a>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        article {
          overflow: hidden;
          margin-bottom: 1rem;
          transition: opacity 0.3s ease;
          &:hover {
            box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #3273dc;
          }
          .card-content {
            color: currentColor;
            .title {
              margin-top: 1em;
              margin-bottom: 1rem;
            }
            @media screen and (max-width: 768px) {
              padding: 1rem;
            }
          }
        }

        .image {
          img {
            object-fit: cover;
          }
        }

        .tag.is-primary.category {
          &-works {
            background: #bf9b26;
          }
          &-blog,
          &-diary,
          &-thought {
            background: #73b61b;
          }
          &-topics {
            background: #1db4bf;
          }
          &-technology,
          &-review {
            background: #9a6de8;
          }
          &-lifestyle {
            background: #f00b98;
          }
          &-entertainment,
          &-media-culture,
          &-anime-game {
            background: #e60012;
          }
        }
      `}</style>
    </article>
  );
};

export default PostOverview;
