const PostOverviewLoading = () => {
  return (
    <article className="box is-paddingless">
      <div className="card-content is-block">
        <div className="tags has-addons">
          <span
            className={`tag is-rounded is-primary is-marginless category-diary`}
          >
            {"　　　　　"}
          </span>
          <span className="tag is-rounded is-marginless">{"　　　"}</span>
        </div>
        <p className="title has-text-link article-title is-size-4 is-size-5-mobile">
          <span className="shimmer"></span>
        </p>
        <div className="my-excerpt">
          <p className="content">
            <span className="shimmer"></span>
            <span className="shimmer"></span>
            <span className="shimmer"></span>
            <span className="shimmer"></span>
          </p>
        </div>
        <div className="buttons is-marginless">
          <div className="button is-white is-paddingless">
            <i className="material-icons mdi-comment is-size-6"></i>
            <span>&nbsp;0</span>
          </div>
          <div className="button is-white is-paddingless has-text-danger">
            <i className="material-icons mdi-favorite is-size-6"></i>
            <span>&nbsp;0</span>
          </div>
        </div>
      </div>
      <style jsx>{`
        article {
          overflow: hidden;
          pointer-events: none;
          margin-bottom: 1rem;
          transition: opacity 0.3s ease;
          &:hover {
            box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px #3273dc;
          }
          .card-content {
            color: currentColor;
            .title {
              margin-top: 1rem;
              margin-bottom: 1rem;
              span {
                font-size: 2rem !important;
              }
            }
            @media screen and (max-width: 768px) {
              padding: 1rem;
            }
          }
        }

        .tag.is-primary.category {
          &-diary {
            background: #73b61b;
          }
        }
      `}</style>
    </article>
  );
};

export default PostOverviewLoading;
