import { useState, useEffect, MutableRefObject } from "react";

export const useOnScreen = (
  ref: MutableRefObject<HTMLDivElement>,
  rootMargin: number = 0,
  once: boolean = true
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!once || entry.isIntersecting) {
          setIntersecting(entry.isIntersecting);
        }
      },
      { rootMargin: `${rootMargin}px` }
    );

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
